import RoutesApp from "./routes";

function App() {
  return (
    <div className="App">
        <RoutesApp/>
    </div>
  );
}

export default App;
